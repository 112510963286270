import {GenericModel} from '../../shared/model/generic.model';

export class LancamentoFinanceiro extends GenericModel {
    	
	public lojaId:number;
    
    public lojaPessoaNomeFantasia:number;

    public lojaTenant: string;
    
    public contaId: number;
    
    public contaNome: string = '';   
    
    public contaCompleto : string = '';

    public contaTenant: string;
    
    public planoContaId: string = '';
    
    public planoContaCodigo: string = '';  
    
    public planoContaDescricao: string = '';  

    public planoContaTenant: string;
    
    public historico: string = '';
    
    public dataLancamento: Date;   
    
    public estado: string;   
    
    public especie: string;   
    
    public operacao: string;   
    
    public tipo: string;   
    
    public valor: number;    
    
    public transferencia: string;   
    
}