
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';
import { MessageService } from '../../shared/service/message.service';
import { Resultado } from '../../shared/util/resultado.model';




@Injectable()
export class EtiquetaColetorService {

    public url_IMPRESSSAO: string = `http://localhost:11952/coletor/imprimir/`;
    private _httpNFE: HttpClient;

    constructor(_http: HttpClient, _message: MessageService) {
        this._httpNFE = _http;
    }

    public enviarComandoImpressora() {
        console.log('ENVIANDO COMANDO PRA IMPRESSORA');
        console.log(this.url_IMPRESSSAO);
        return this._httpNFE.get(`${this.url_IMPRESSSAO}`).toPromise()
            .then(
                res => {
                    <Resultado>res;
                    console.log(res)
                },
                erro => {
                    console.log('Erro ao enviar comando para coletor /n');
                    console.log(erro);
                }
            );


    }

    private handleErrorNFE(error: Response) {
        console.error(error);
        return observableThrowError(error || 'Server error');
    }

}


