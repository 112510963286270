import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pedido } from '../../comercial/pedido/pedido.model';
import { PageResult, SearchParams } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { Relatorio } from '../../shared/util/relatorio.model';


@Injectable()
export class TransferenciaLojasService extends VsCrudService<Pedido> {
  
  public url: string = "api/transferenciaLojas";
  pageResult: PageResult = new PageResult();

   constructor(_http: HttpClient, _message: MessageService) { 
        super(<any>_http, _message);
   }
   
    public list(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/search', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }


    public imprimirPedido(id: Number) {        
        let resultado:any;
        return this.get(`${this.url}/imprimir/${id}`).toPromise().then(res => 
              <Relatorio>res);     
    }

    public imprimirPedidoSeparacao(id: Number) {        
        let resultado:any;
        return this.get(`${this.url}/imprimirSeparacao/${id}`).toPromise().then(res => 
              <Relatorio>res);     
    }

}


