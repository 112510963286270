import {GenericModel} from '../../shared/model/generic.model';

export class EquipamentoImagem extends GenericModel {
 
   public caminhoImagem:string='';
   public nome:string;
   public tamanho:number;
   
   

}
