import {GenericModel} from '../../shared/model/generic.model';

export class Fabricante extends GenericModel {
    
  public descricao:string='';
  public tipoFabricante:string = '';
  public caminhoImagem:string = '';
  public nomeImagem:string = '';
  public tamanhoImagem:number;
}
