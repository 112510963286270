import {GenericModel} from '../../shared/model/generic.model';
import { ParcelaPagar } from './parcela-pagar.model';

export class DuplicataPagar extends GenericModel {

    public lojaId:number;
	
	public lojaPessoaNomeFantasia:string;

	public numeroDuplicata:string;

	public favorecidoId:number;
	
	public favorecidoPessoaNomeFantasia:string;

	public favorecidoTenant: string;

	public bancoId:number;
	
	public bancoDescricao:string;

	public dataEmissao:Date;

	public valorDuplicata:number;

	public quantidadeParcelas:number;

	public observacao:string;

	public historico:string;

	public despesaFixa:boolean;

	public pago:boolean;

	public repetir:boolean;

	public parcelas:  Array<ParcelaPagar>;

	

}
