import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageResult } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { GrupoTributoFavorecido } from './grupo-tributo-favorecido.model';
import { map } from 'rxjs/operators';




@Injectable()
export class GrupoTributoFavorecidoService extends VsCrudService<GrupoTributoFavorecido> {
  
  public url: string = "api/grupoTributoFavorecidos";
  pageResult: PageResult = new PageResult();

   constructor(_http: HttpClient, _message: MessageService) { 
        super(<any>_http, _message);
   }


   public findByPadrao(){
     return this.get(`${this.url}/findByPadrao/`).pipe(map(res => <GrupoTributoFavorecido>res));
   }
}


