import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageResult } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { ModeloDocumentoFiscal } from './modelo-documento-fiscal.model';




@Injectable()
export class ModeloDocumentoFiscalService extends VsCrudService<ModeloDocumentoFiscal>{

    public url: string = "api/modelosDocumentosFiscais";
    pageResult: PageResult = new PageResult();

   constructor(_http: HttpClient, _message: MessageService) { 
        super(<any>_http, _message);
   }

}