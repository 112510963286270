import {GenericModel} from '../../shared/model/generic.model';

export class ConfiguracaoTributo extends GenericModel {
    
    public lojaId:                           string;
    public lojaPessoaNomeFantasia:           string;
    public lojaTenant:                       string;
    public grupoTributoFavorecidoId:         number;
    public grupoTributoFavorecidoDescricao:  string;
    public grupoTributoFavorecidoTenant:     string;
    public convenioId:                       number;
    public convenioDescricao:                string;
    public convenioTenant:                   string;
    public uf:                               string;
    public grupoTributoProdutoId:            number;
    public grupoTributoProdutoDescricao:     string;
    public grupoTributoTenant:               string;
    public cfopDescricao :                   string;
    public cfopCompleto  :                   string;
    public cfopId:                           number;
    public situacaoTributariaId:             number;
    public situacaoTributariaDescricao:      string;
    public situacaoTributariaCompleto :      string;
    public codBenFiscal               :      string;
    public situacaoTributariaTenant:         string;
    public icmsInterno:                      number;
    public aliquotaIcms:                     number;
    public ivaSubstituicao:                  number;
    public outorgado:                        number;
    public icmsInterEstadual:                number;
    public aliquotaEcf:                      number;
    public icmsSubstituicao:                 number;
    public reducaoBaseIcms:                  number;
    public reducaoBaseIva:                   number;
    public calcularIpiIcms:                  boolean;
    public calcularIpiSubstituicao:          boolean;

}