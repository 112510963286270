
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PageResult } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { SituacaoTributaria } from './situacao-tributaria.model';




@Injectable()
export class SituacaoTributariaService extends VsCrudService<SituacaoTributaria> {
  
  public url: string = "api/situacoesTributarias";
  pageResult: PageResult = new PageResult();

   constructor(_http: HttpClient, _message: MessageService) { 
        super(<any>_http, _message);
   }

   public findByCodigo(codigo: string){
    return this.get(`${this.url}/codigo/${codigo}`).pipe(map(res => <SituacaoTributaria>res));
  }
}