import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageResult } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { CSTPisCofins } from './cst-pis-cofins.model';




@Injectable()
export class CSTPisCofinsService extends VsCrudService<CSTPisCofins> {
  
  public url: string = "api/CSTsPisCofins";
  pageResult: PageResult = new PageResult();

   constructor(_http: HttpClient, _message: MessageService) { 
        super(<any>_http, _message);
   }
}