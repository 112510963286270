import {GenericModel} from '../../shared/model/generic.model';

export class ItensNFEntrada extends GenericModel {

	public  lojaId: number;
	
	public idPrazo:number;
	
	public  lojaPessoaNomeFantasia: string;
	
	public  numero: number;
	
	public  produtoId: number;
	
	public  produtoDescricao: string;

	public produtoCodigoInterno:number; //new

	public  produtoCodigoReferencia: string;//new
	
	public  sequencia: number;
	
	public  valorOperacional: number;
	
	public  valorCompra: number;
	
	public  quantidade: number;

	//Usado quando a unidade da nota é convertida na entrada
	public  quantidadeNota: number;

	
	public  valorUnitario: number;

	//Usado quando a unidade da nota é convertida na entrada
	public  valorUnitarioNota: number;
	
	public  valorDescontoItens: number;
	
	public  percentualDescontoItens: number;
	
	public  valorUnitarioLiquido: number;
	
	public  valorDescontoFechamento: number;
	
	public  valorTotal: number;
	
	public  baixouEstoque: string;
	
	public  itemImpresso: string;
	
	public  produtoServico: string;

	public unidadeNotaId:number;

	public unidadeConvertidaId: number;

	public  baseIcms: number;
	
	public  valorIcms: number;

	public  valorOutras: number;
	
	public  valorISSQN: number;
	
	public  valorIsenta: number;

	public  valorIPI: number;
	
	public  valorFrete: number;
	
	public  valorOutrasDespesas: number;
	
	public  valorSeguro: number;

	public  aliquotaIPI: number;
	
	public  aliquotaPis: number;

	public  valorPis: number;
	
	public  cstPisCofins :string;

	public  aliquotaIcms: number;
	
	public  aliquotaIVA: number;

	public  aliquotaIcmsIVA: number;

	public  aliquotaCofins: number;
	
	public  valorCofins: number;
	
	public  coeficienteReducao: number;
	
	public  coeficienteReducaoIVA: number;

	public  baseIcmsSubstituicao: number;

	public  valorIcmsSubstituicao: number;
	
	public  cfop: string;
	
	public  situacaoTributaria: string;

	public dataVenda: Date;


}