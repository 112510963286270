import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ItensPedido } from '../../comercial/pedido/itens-pedido';
import { PageResult } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';


@Injectable()
export class ItensTransferenciaLojasService extends VsCrudService<ItensPedido> {

   public url: string = "api/itensPedido";
   pageResult: PageResult = new PageResult();

   constructor(_http: HttpClient, _message: MessageService) {
      super(<any>_http, _message);
   }

   public excluirItemPedido(idItem: Number, idPedido: Number) {
      return this.get(`${this.url}/excluirItemPedido/${idItem}/${idPedido}`).toPromise()
         .then(res => <boolean>res);
   }

   public atualizarValoresPedido(idPedido: Number, valorUnitario: Number, quantidade: Number, valorDescontoItem: Number, produtoServico: String) {
      return this.get(`${this.url}/atualizarValoresPedido/${idPedido}/${valorUnitario}/${quantidade}/${valorDescontoItem}/${produtoServico}`).toPromise()
         .then(res => <boolean>res);
   }


}


