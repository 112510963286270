import {GenericModel} from '../../shared/model/generic.model';

export class PlanoContas extends GenericModel {
    

  public paiId: number;
  public paiDescricao:string;
  public codigo: string;
  public numero: string;
  public descricao:string ='';
  public operacao: string;


}
