import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageResult, SearchParams } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { Relatorio } from '../../shared/util/relatorio.model';
import { EtiquetaProdutoRelatorio } from './etiqueta-produto-relatorio.model';
import { ProdutoListaRelatorio } from './rel-etiqueta-lista-produtos.model';





@Injectable()
export class RelEtiquetaProdutoService extends VsCrudService<EtiquetaProdutoRelatorio> {

    public url: string = "api/etiquetas";
    public urlEtiqueta: string = "api/etiquetas";


    pageResult: PageResult = new PageResult();

    constructor(_http: HttpClient, _message: MessageService) {
        super(<any>_http, _message);
    }

    public list(search: SearchParams) {
        let self = this;

        return this.search(this.url + '/searchRelatorioEtiqueta', search)
            .subscribe(res => {

                self.pageResult = res;
                self.pageResult.content.forEach((value) => {
                    value.saldoEstoque = value.saldoEstoque + ',00';

                })

            });
    }

    public imprimir(idLoja: Number,
        idCategoria: Number,
        idMarca: Number,
        idFabricante: Number,
        idUnidade: Number,
        etiquetaTamanho: String,
        tipoImpressora: String,
        dataNota: String,
        numeroNota: String,
        filtrarPorNota: boolean,
        idFornecedor: Number,
        idProduto: Number,
        listaPreco: Number,
        quantidade: Number,
        dataInicioCadastro: String,
        dataFimCadastro: String
    ) {
        let resultado: any;
        return this.get(`${this.url}/imprimirRelatorioEtiqueta/${idLoja}/${idCategoria}/${idMarca}/${idFabricante}/${idUnidade}/${etiquetaTamanho}/${tipoImpressora}/${dataNota}/${numeroNota}/${filtrarPorNota}/${idFornecedor}/${idProduto}/${listaPreco}/${quantidade}/${dataInicioCadastro}/${dataFimCadastro}`).toPromise().then(res =>

            <Relatorio>res);

    }

    public imprimirListaPersonalizada(params: ProdutoListaRelatorio[]) {
        return this.post(this.url + "/imprimirListaPersonalizada", params)
            .toPromise().then(res =>
                <Relatorio>res
            );
    };

}


