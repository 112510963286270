import { GenericModel } from '../../shared/model/generic.model';

export class ParcelaPagar extends GenericModel {

	public duplicataPagarId: number;

	//public duplicataPagarLojaId:number;

	//public duplicataPagarLojaNomeFantasia:string;

	public duplicataPagarNumeroDuplicata: string;

	public duplicataPagarFavorecidoId: number;

	public duplicataPagarFavorecidoPessoaNomeFantasia: string;

	public bancoId: number;

	public bancoDescricao: string;

	public contaId: number;

	public contaDescricao: string;

	public planoContaId: number;

	public planoContaDescricao: string;

	public dataEmissao: Date;

	public dataVencimento: Date;

	public dataCancelamento: Date;

	public dataPagamento: Date;

	public dataEstorno: Date;

	public estado: String;

	public valorParcela: number = 0;

	public valorPago: number = 0;

	public valorJuros: number = 0;

	public valorDesconto: number = 0;

	public valorProtesto: number = 0;

	public multa: number = 0;

	public numeroParcela: number;

	public observacaoParcela: String;

	public observacaoCancelamento: String;

	public observacaoEstorno: String;

	public idMotivoCancelamento: number;

	public idUsuarioPagamento: number;

	public idUsuarioCancelamento: number;

	public idUsuarioEstorno: number;

	public idOrigem: number;

	public origem: string;


}
