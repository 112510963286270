import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageResult, SearchParams } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { NFEntrada } from './nf-entrada.model';




@Injectable()
export class EntradaCompraService extends VsCrudService<NFEntrada> {
  
  public url: string = "api/nfCompra";
  pageResult: PageResult = new PageResult();

   constructor(_http: HttpClient, _message: MessageService) { 
        super(<any>_http, _message);
   }
   
    public list(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/searchCompras', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }


}


