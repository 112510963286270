import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImportacaoXLS } from '../../configuracoes/importacao-xls/importacao-xls.model';
import { PageResult, SearchParams } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { Equipamento } from './equipamento.model';




@Injectable()
export class EquipamentoService extends VsCrudService<Equipamento> {

    public url: string = "api/equipamentos";
    pageResult: PageResult = new PageResult();

    constructor(_http: HttpClient, _message: MessageService) {
        super(<any>_http, _message);
    }

    public list(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/search', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }

    public importarArquivoXLS(caminho: string) {
        return this.get(`${this.url}/importarXLS/${caminho}`)
            .toPromise()
            .then(res => <ImportacaoXLS>res);
    }

    public findByPlaca(placa: string) {
        return this.get(`${this.url}/placa/${placa}`).pipe(map(res => <Equipamento>res));
    }

    public findByPedidoId(id: number): Observable<Equipamento> {
        return this.get(`${this.url}/equipamento/${id}`)
            .pipe(
                map(res => res as Equipamento)
            );
    }

}

