import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageResult } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { Contrato } from './contrato.model';

@Injectable()
export class ContratoService extends VsCrudService<Contrato> {

  public url: string = "api/contratos";
  pageResult: PageResult = new PageResult();

   constructor(_http: HttpClient, _message: MessageService) {
        super(<any>_http, _message);
   }


}


