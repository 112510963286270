import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageResult } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { ProdutoFornecedor } from './produto-fornecedor.model';




@Injectable()
export class ProdutoFornecedorService extends VsCrudService<ProdutoFornecedor> {

  public url: string = "api/produtosFornecedor";
  pageResult: PageResult = new PageResult();

  constructor(_http: HttpClient, _message: MessageService) {
    super(<any>_http, _message);
  }

  public findByCodigoReferencia(codigoReferencia: string, codigoFornecedor: number) {
    return this.get(`${this.url}/referencia/${codigoReferencia}/${codigoFornecedor}`)
    .toPromise()
    .then(res =><ProdutoFornecedor>res);    
  }

  public listProdutosFornecedor(codigoFornecedor:number){
     return this.get(`${this.url}/listProdutosFornecedor/${codigoFornecedor}`).toPromise()
     .then(res =><ProdutoFornecedor>res);    
     
  }

  public excluirProdutoFornecedor(id: number) {
    return this.get(`${this.url}/excluir/${id}`).
    toPromise()
    .then(res =><boolean>res);
 
  }

  
  
  /*public findByCodigoReferenciaLista(listaProdutos:any[]) {
    return this.get(`${this.url}/referenciaLista/${listaProdutos}`)
    .toPromise()
    .then(res => <ProdutoFornecedor[]> res.data)
    .then(data => { return data; });
   
  }*/

 


}


