
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SetupService } from '../../configuracoes/setup/setup.service';
import { MessageService } from '../../shared/service/message.service';
import { Resultado } from '../../shared/util/resultado.model';

@Injectable()
export class ImpressaoColetorService {

    // public url_NFE: string = `http://localhost:11952/coletor/pedido/imprimir`;
    public url_NFE: string = '';

    private _httpNFE: HttpClient;

    constructor(_http: HttpClient,
        _message: MessageService,
        public _setupService: SetupService) {
        this._httpNFE = _http;
    }


    public imprimirPedidoLocalPdv(id: Number, idSetup: number) {
        let resultado: any;
        let endereco_URI = "pedido/imprimir";
        this._setupService.findOne(idSetup).subscribe(
            res => {
                this.url_NFE = res.enderecoImpressora;
                return this._httpNFE.get(`${this.url_NFE}/${endereco_URI}/${id}`).toPromise().then(
                    res => {
                        <Resultado>res;
                        console.log('Imprimir pedido local pdv ...')
                        console.log(`${this.url_NFE}/${endereco_URI}/${id}`)
                        console.log(res);
                    },
                    erro => console.log(erro)
                );
            }
        )
    }

    public imprimirNFCELocal(id: Number, idSetup: number,arquivoPDF: String) {
        let resultado: any;
        let endereco_URI = "pedido/imprimirNFCE";
        this._setupService.findOne(idSetup).subscribe(
            res => {
                this.url_NFE = res.enderecoImpressora;
                return this._httpNFE.get(`${this.url_NFE}/${endereco_URI}/${id}/${arquivoPDF}`).toPromise().then(
                    res => {
                        <Resultado>res;
                        console.log('Imprimir NFCE Local')
                        console.log(`${this.url_NFE}/${endereco_URI}/${id}/${arquivoPDF}`)
                        console.log(res);
                    },
                    erro => console.log(erro)
                );
            }
        )
    }

    // public imprimirPedidoLocal(id: Number) {
    //     let resultado: any;
    //     let endereco_URI = "pedido/imprimir";
    //     return this._httpNFE.get(`${this.url_NFE}/${endereco_URI}/${id}`).toPromise().then(
    //         res => {
    //             <Resultado>res;
    //             console.log('imprimir pedido local')
    //             console.log(`${this.url_NFE}/${endereco_URI}/${id}`)
    //             console.log(res);
    //         },
    //         erro => console.log(erro)
    //     );
    // }

    public imprimirPedidoSeparacaoTermica(id: Number, idSetup: number) {
        let resultado: any;
        let endereco_URI = "pedido/separacao";
        this._setupService.findOne(idSetup).subscribe(
            res => {
                this.url_NFE = res.enderecoImpressoraSeparacao;
                return this._httpNFE.get(`${this.url_NFE}/${endereco_URI}/${id}`).toPromise().then(
                    res => {
                        <Resultado>res;
                        console.log('Imprimir pedido separacao termica')
                        console.log(`${this.url_NFE}/${endereco_URI}/${id}`)
                        console.log(res);
                    },
                    erro => console.log(erro)
                );
            }
        )
    }

}


