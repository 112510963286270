import { GenericModel } from '../../shared/model/generic.model';


export class ProdutoCTAModel extends GenericModel {

    public codigoInterno: number;

    public codigoReferencia: string;

    public codigoOriginal: string;

    public codigoBarra: string;

    public outroCodigo: string;

    public descricao: string;

    public aplicacao: string;

    public tipoProduto: string;

    public grupoTributoProdutoId: number;

    public marcaDescricao: string;

    public categoriaDescricao: string;

    public unidadeId: number;

    public unidadeSigla: string;

    public unidadeTipoMedida: string;

    public estoques: Array<any>;

    public imagens: Array<any>;

    public precos: Array<any>;

    public preco: Object;

    public produtoEquivalentes: Array<any>;

    public quantidadeNota: number;

    public quantidade: any;
}