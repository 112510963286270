import {GenericModel} from '../../shared/model/generic.model';

export class Prazo extends GenericModel {
    
  public descricao :string;
  public qtdeParcelas : number;
  public tipoPrazo: number;
  public diaFixo: number;
  public intervalo: number;
  public diaSemana: number;
  public tipoOperacao: number;
  public parcelasPrazo: Array<any>;

    
}