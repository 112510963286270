import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImportacaoXLS } from '../../configuracoes/importacao-xls/importacao-xls.model';
import { PageResult, SearchParams } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { Fabricante } from './fabricante.model';




@Injectable()
export class FabricanteService extends VsCrudService<Fabricante> {
  
  public url: string = "api/fabricantes";
  pageResult: PageResult = new PageResult();

   constructor(_http: HttpClient, _message: MessageService) { 
        super(<any>_http, _message);
   }

   public list(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/search', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }

   public importarArquivoXLS(caminho: string) {
        return this.get(`${this.url}/importarXLS/${caminho}`)
                    .toPromise()
                    .then(res =><ImportacaoXLS>res);

   }

}

