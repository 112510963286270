
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { MessageService } from '../../shared/service/message.service';



@Injectable()
export class ConsultaIbgeService {

    public url_consulta: string = `http://servicodados.ibge.gov.br/api/v1/localidades/municipios`;
    private _httpConsulta: HttpClient;
    public result;

    constructor(_http: HttpClient, _message: MessageService) {
        this._httpConsulta = _http;
    }


    /*public  consultarIbge(cidade: string): ResultadoIbge{
       let resultado:ResultadoIbge;
       
        this._httpConsulta.get(`${this.url_consulta}/${cidade}`).subscribe(res => {
                                       resultado = res;
                                       console.log(res)
                                   }
                               );
        return resultado;
        

    }*/

    public consultarIbge(cidade: string) {

        return this._httpConsulta.get(`${this.url_consulta}/${cidade}`).pipe(map(res => res));
    }


}