
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PageResult } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { CFOP } from './cfop.model';




@Injectable()
export class CFOPService extends VsCrudService<CFOP> {
  
  public url: string = "api/cfops";
  pageResult: PageResult = new PageResult();

   constructor(_http: HttpClient, _message: MessageService) { 
        super(<any>_http, _message);
   }
   public findByCFOP(pesquisa: string){
    return this.get(`${this.url}/cfop/${pesquisa}`).pipe(map(res => <CFOP>res));
  }
  public pesquisarCFOP(pesquisa: string){
    return this.get(`${this.url}/cfop/${pesquisa}`).toPromise()
    .then(res =><CFOP>res);
  }
}


