import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageResult } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { ProdutoEquivalente } from './produto-equivalente.model';




@Injectable()
export class ProdutoEquivalenteService extends VsCrudService<ProdutoEquivalente> {

  public url: string = "api/produtoEquivalentes";
  pageResult: PageResult = new PageResult();

  constructor(_http: HttpClient, _message: MessageService) {
    super(<any>_http, _message);
  }

  public excluirPorEquivalencia(idProduto: number, idEquivalente: number) {
    return this.get(`${this.url}/excluir/${idProduto}&${idEquivalente}`).
      toPromise()
      .then(res => <boolean>res);
    //map(res => <boolean>res);        
  }


}


