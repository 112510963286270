import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

import { ImportacaoXLS } from '../../configuracoes/importacao-xls/importacao-xls.model';
import { PageResult, SearchParams } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { Relatorio } from '../../shared/util/relatorio.model';
import { EstoqueAuditoria } from './estoque-auditoria.model';


@Injectable()
export class EstoqueAuditoriaService extends VsCrudService<EstoqueAuditoria> {

    public url: string = "api/estoquesAuditoria";

    pageResult: PageResult = new PageResult();

    constructor(_http: HttpClient, _message: MessageService) {
        super(<any>_http, _message);
    }

    public list(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/searchRelatorio', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }

    public imprimir(loja: number, produto: number, dataInicio: string, dataFim: string) {
        let resultado: any;
        return this.get(`${this.url}/imprimir/${loja}/${produto}/${dataInicio}/${dataFim}`).toPromise().then(res =>
            <Relatorio>res);
    }

    public importarArquivoXLS(caminho: string) {
        return this.get(`${this.url}/importarXLS/${caminho}`)
            .toPromise()
            .then(res => <ImportacaoXLS>res);

    }

}


