import {GenericModel} from '../../shared/model/generic.model';

export class PedidoCompra extends GenericModel {

	public numero:number;

  public lojaId:number;

	public lojaPessoaNomeFantasia:string;

  public itensPedidoCompra:  Array<any>;

	public operacaoId:number;

	public operacaoDescricao:string;

	public prazoId:number;

	public prazoDescricao:string;

	public colaboradorId:number;

	public colaboradorPessoaNomeFantasia:string;

	public transportadoraId:number;

	public transportadoraPessoaNomeFantasia:string;

	public favorecidoId:number;

	public favorecidoPessoaNomeFantasia:string;

	public nomeFornecedor:string;

	public formaPagamentoDescricao:string;

	public formaPagamentoId:number;

	public valorFrete:number = 0;

	public valorSeguro:number = 0;

	public valorOutrasDespesas:number = 0;

	public valorIPI:number = 0;

	public valorIcmsSubstituicao:number = 0;

	public totalBrutoProduto:number = 0;

	public totalLiquidoProduto:number = 0;

	public valorDescontoProdutoItens:number = 0;

	public percentualDescontoProdutoItens:number = 0;

	public valorDescontoProdutoFechamento:number = 0;

	public percentualDescontoProdutoFechamento:number = 0;

	public subTotal:number = 0;

	public total:number = 0;

	public observacao:string;

	public observacaoNotaFiscal:number;

	public dataCompra:Date;

	public dataFaturamento:Date;

	public dataCancelamento:Date;

	public dataSaida:Date;

	public numeroNF:number;

	public cotacao:string;

	public faturado:string;

	public tipoFrete:string;

	public cancelado:string;

	public especie:string;

  public volume:string;

  public pesoLiquido:number;

	public pesoBruto:number;
}
