import { GenericModel } from '../../shared/model/generic.model';

export class Endereco extends GenericModel {

    public bairro: string = '';
    public cidade: string = '';
    public cep: string;
    public logradouro: string = '';
    public uf: string = '';    
    public complemento: string = '';
    public codigoIbge: string = '';
    public numero: string = '';
    public tipoEndereco : string = '';
    
}