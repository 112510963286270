import { GenericModel } from '../../shared/model/generic.model';
import { EquipamentoImagem } from './equipamento-imagem.model';
import { VeiculoProdutoCTA } from './veiculo-produto-cta.model';

export class Equipamento extends GenericModel {    
    
    public favorecidoId            : number;                    
    public descricao               : string;
    public estado:           string;
    public serie:            string;
    
    public placa:            string;
    public chassi:           string;    
    public modeloId:         number;
    public modeloDescricao:  string;
    public corPredominante:  number;

    public versao:           string;
    public observacao:       string;
    public combustivel:      string;
    public tipo:             string;
    public tipoCambio:       string;
    public anoModelo:        string;
    public anoFabricacao:    string;
    public numeroMotor:      number;
    public renavam:          string;
    public caminhoImagem:    string;
    public tamanhoImagem:    any;
    public equipamentoImagens: Array<EquipamentoImagem>;
    public veiculoProdutos: Array<VeiculoProdutoCTA>;

}
