import { GenericModel } from '../../shared/model/generic.model';

export class FechamentoConta extends GenericModel {

    public idUsuarioFechamento: number;
    public contaId: number;
    public contaNome: string = '';

    public idLoja: number;
    public lojaDescricao: string = '';
    public contaDescricao: string = '';
    public dataInicio: Date;
    public dataFim: Date;
    public dataSaldo: Date;
    public dataSaldoAnterior: Date;

    public saldoDinheiroAnterior: number;
    public creditoDinheiro: number;
    public debitoDinheiro: number;
    public totalDinheiro: number;

    public saldoBoletoAnterior: number;
    public creditoBoleto: number;
    public debitoBoleto: number;
    public totalBoleto: number;

    public saldoChequeAnterior: number;
    public creditoCheque: number;
    public debitoCheque: number;
    public totalCheque: number;

    public creditoFuturo: number;
    public debitoFuturo: number;
    public totalLancamentosFuturos;

    public saldoCartaoDebitoAnterior: number;
    public creditoCartaoDebito: number;
    public debitoCartaoDebito: number;
    public totalCartaoDebito: number;

    public saldoCartaoCreditoAnterior: number;
    public creditoCartaoCredito: number;
    public debitoCartaoCredito: number;
    public totalCartaoCredito: number;

    public saldoPixAnterior: number;
    public creditoPix: number;
    public debitoPix: number;
    public totalPix: number;

    public saldoTotal: number;
    public status: string = '';

}
