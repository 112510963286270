import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageResult, SearchParams } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { Relatorio } from '../../shared/util/relatorio.model';
import { Resultado } from '../../shared/util/resultado.model';
import { ParcelaReceberBaixa } from '../baixa-parcela-receber/parcela-receber-baixa.model';
import { ParcelaReceber } from './parcela-receber.model';
import { ImportacaoXLS } from '../../configuracoes/importacao-xls/importacao-xls.model';
import { take, tap } from 'rxjs/operators';

@Injectable()
export class ParcelaReceberService extends VsCrudService<ParcelaReceber> {

    public url: string = "api/parcelasReceber";
    pageResult: PageResult = new PageResult();

    constructor(_http: HttpClient, _message: MessageService) {
        super(<any>_http, _message);
    }

    public list(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/search', search).subscribe(
            res => {
                self.pageResult = res;
                tap(console.log)
            }
        );
    }

    public listDinamico(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/searchDinamico', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }

    public listParcelas(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/searchParcelas', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }

    public gerarBoletoItauByParcela(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/gerarBoletoItauByParcela', search)
        
    }

    public imprimirBoleto(idDuplicataReceber: number, idParcelaReceber: number) {
        return this.get(`${this.url}/imprimirBoleto/${idDuplicataReceber}&${idParcelaReceber}`)
            .toPromise()
            .then(res => <Relatorio>res);
    }

    public gerarBoletoBB(idDuplicataReceber: number, idParcelaReceber: number) {
      return this.get(`${this.url}/gerarBoletoBB/${idDuplicataReceber}&${idParcelaReceber}`)
          .toPromise()
          .then(res => <Resultado>res);
    }

    public estornar(id: number) {
        return this.get(`${this.url}/estornar/${id}`)
            .toPromise()
            .then(res => <Resultado>res);
    }

    public cancelar(id: number) {
        return this.get(`${this.url}/cancelar/${id}`)
            .toPromise()
            .then(res => <Resultado>res);
    }

    public baixarUnica(parcelaReceberBaixa: ParcelaReceberBaixa) {
        return this.post(this.url + '/baixarUnica', parcelaReceberBaixa);
    }

    public gerarExtratoDuplicataReceber(idLoja: Number, idDuplicataReceber: number, idParcelaReceberExtrato: number) {
        return this.get(`${this.url}/imprimirExtratoDuplicataReceber/${idLoja}/${idDuplicataReceber}/${idParcelaReceberExtrato}`)
            .toPromise()
            .then(res => <Relatorio>res);
    }

    public gerarExtratoParcela(idParcela: number) {
        return this.get(`${this.url}/extratoParcela/${idParcela}`)
            .toPromise().then(res => <Relatorio>res);
    }

    public importarBoletoTXT(caminho: string) {
      return this.get(`${this.url}/importarTXT/${caminho}`)
                     .toPromise()
                     .then(res =><ImportacaoXLS>res);
    }

    public numeroDuplicata(){
        return this.get(`${this.url}/numeroDuplicata`).pipe(
            take(1),
            tap(console.log)
        )
    }

    
    public validarClienteIsBloqueadoByLimite(tenant: string, idLoja: Number, idFavorecido: Number, total: Number) {
        return this.get(`${this.url}/validarClienteIsBloqueadoByLimite/${tenant}/${idLoja}/${idFavorecido}/${total}`)
    }
}


