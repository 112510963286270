import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageResult, SearchParams } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { Relatorio } from '../../shared/util/relatorio.model';
import { Pedido } from '../pedido/pedido.model';

@Injectable()
export class PedidoService extends VsCrudService<Pedido> {

    public url: string = "api/pedidos";

    public url_NFE: string = "http://192.168.0.99:8080/integracao/gravar/26";
    pageResult: PageResult = new PageResult();
    private httpNFE: HttpClient;

    constructor(_http: HttpClient, _message: MessageService) {
        super(<any>_http, _message);
        this.httpNFE = _http;
    }

    public list(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/search', search)
            .subscribe(res => {
                self.pageResult = res;
            }
        );
    }

    public listPersonalizado(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/searchPersonalizado', search)
            .subscribe(res => {
                self.pageResult = res;
            }
        );
    }

    public listPersonalizadoCancelamento(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/searchPersonalizadoCancelamento', search)
            .subscribe(res => {
                self.pageResult = res;
            }
        );
    }

    public imprimirPedido(id: Number, tela: string) {
        let resultado: any;
        return this.get(`${this.url}/imprimir/${id}/${tela}`).toPromise().then(res =>
            <Relatorio>res);
    }

    public imprimirPedidoSeparacao(id: Number) {
        let resultado: any;
        return this.get(`${this.url}/imprimirSeparacao/${id}`).toPromise().then(res =>
            <Relatorio>res);
    }

    public imprimirNFCE(id: Number) {
      let resultado: any;
      return this.get(`${this.url}/imprimirNFCE/${id}`).toPromise().then(res =>
          <Relatorio>res);
     }

     public imprimirNFE(id: Number) {
      let resultado: any;
      return this.get(`${this.url}/imprimirNFE/${id}`).toPromise().then(res =>
          <Relatorio>res);
    }

    public savePdv(param: Object) {
        return this.postPedido(`${this.url}/savePDV`, param);
    }

    public alteraPedido(param: Object) {
        return this.postPedido(`${this.url}/alteraPedido`, param);
    }

}



