
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';
import { MessageService } from '../../shared/service/message.service';
import { Resultado } from '../../shared/util/resultado.model';




@Injectable()
export class NFEIntegracaoService  {
  
  public url_NFE: string = `http://localhost:11952/coletor/integracao/gravar`;  
  private _httpNFE:HttpClient;

   constructor(_http: HttpClient,_message: MessageService) {         
        this._httpNFE = _http;
   }
   
    public gerarArquivoNFE(id: Number) {        
        let resultado:any;
        
        return this._httpNFE.get(`${this.url_NFE}/${id}`).toPromise().then(res => {
                                        <Resultado>res;
                                        console.log(res)
                                    }
                                );
                  
        
    }

    private handleErrorNFE(error: any) {
        console.error(error);
        return observableThrowError(error.error || 'Server error');
    }
    // return this._httpNFE.get(this.url_NFE)
    //          .map((response: Response) => <Resultado>response)
    //          .do(data => console.log('All: ' + JSON.stringify(data)))
    //          .catch(this.handleErrorNFE);

         


}


