import { GenericModel } from '../../shared/model/generic.model';

export class Modelo extends GenericModel {


    /* 1 - Carros e utilitarios
       2 - Motos e Quadriclos
       3 - Caminhoes       
       4 - Carretas e Carrocerias
       5 - Onibus e Vans
       6 - Nauticos      
    */
    public tipoVeiculo: string;
    public tipo: string;

    public fabricanteId: number;
    public fabricanteDescricao: string;
    public descricao: string;
    public cilindradas: number;
    public potencia: string;
    public peso: number;
    public versao: string;
    public qtdePessoas: number;
    public anos: Array<any>;

}
