import { GenericModel } from '../../shared/model/generic.model';

export class PDVPagamento extends GenericModel {

    public descricao: string;
    public prazoId: number;
    public prazoDescricao: string;
    public prazoTenant: string;
    public formaPagamentoId: number;
    public formaPagamentoDescricao: string;
    public formaPagamentoBoleto: string;
    public formaPagamentoPix: string;
    public formaPagamentoCheque: string;
    public formaPagamentoCartaoCredito: string;
    public formaPagamentoCartaoDebito: string;
    public formaPagamentoDinheiro: string;
    public formaPagamentoOutros: string;
    public formaPagamentoConvenio: string;
    public formaPagamentoTenant: string;
    public credenciadoraCartaoId: number;
    public credenciadoraCartaoNome: string;
    public credenciadoraTenant: string;
    public bandeiraCartaoId: number;
    public bandeiraCartaoNome: string;

    public bandeiraTenant: string;
    public planoContaCreditoId: number;
    public planoContaCreditoDescricao: string;
    public planoContaDebitoId: number;
    public planoContaDebitoDescricao: string;
    public planoContaTenant: string;
    public taxa: number;
    public imprimirPedido: boolean;
    public lancamentoContaBancaria: Boolean;
    public contaId: number;
    public contaNome: string;
    public contaTenant: string;

}