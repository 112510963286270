import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageResult, SearchParams } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { LancamentoFinanceiro } from './lancamento-financeiro.model';


type NewType = Response;

@Injectable()
export class LancamentosFinanceirosService extends VsCrudService<LancamentoFinanceiro>{

    public url: string = "api/lancamentosFinanceiro";
    pageResult: PageResult = new PageResult();

    constructor(_http: HttpClient, _message: MessageService) {
        super(<any>_http, _message);
    }

    public list(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/search', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }

    public listTransferencia(search: SearchParams) {
        let self = this;

        return this.search(this.url + '/searchTransferencia', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }

    public consultarFechamentoPorData(model: LancamentoFinanceiro): Observable<Response> {
        return this.post(this.url + '/consultarFechamento', model);
    }

    public gerarLancamentoEstorno(model: LancamentoFinanceiro): Observable<Response> {
        return this.post(this.url + '/gerarLancamentoEstorno', model);
      }


    public searchDinamico(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/searchDinamico', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }

}