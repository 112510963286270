import {GenericModel} from '../../shared/model/generic.model';

export class SituacaoCliente extends GenericModel {
    
   public descricao:string;
   public observacao:string;
   public atualiza  :boolean = false;
   public bloqueado :boolean = false;

}
