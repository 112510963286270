import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PageResult, SearchParams } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { Fabricante } from './fabricante.model';
import { Marca } from './marca.model';





const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class FabricanteService extends VsCrudService<Fabricante> {

  public url: string = "api/fabricantes";
  pageResult: PageResult = new PageResult();
  private _httpFIPE: HttpClient;

  constructor(_http: HttpClient, _message: MessageService,
    private _httpNovo: HttpClient) {
    super(<any>_http, _message);
  }

  public list(search: SearchParams) {
    let self = this;
    return this.search(this.url + '/search', search)
      .subscribe(res => {
        self.pageResult = res;
      });
  }


}

