import {GenericModel} from '../../shared/model/generic.model';

export class FormaPagamento extends GenericModel {
    
    public descricao: string = '';
    public boleto : any = '';
    public pix: any = '';
    public cheque : any = '';
    public cartaoCredito : any = '';
    public cartaoDebito : any = '';
    public dinheiro : any = '';
    public outros : any = '';
    public convenio : any = '';

    
}