import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageResult } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { CustoOperacional } from './custo-operacional.model';




@Injectable()
export class CustoOperacionalService extends VsCrudService<CustoOperacional> {

  public url: string = "api/custoOperacionais";
  pageResult: PageResult = new PageResult();

  constructor(_http: HttpClient, _message: MessageService) {
    super(<any>_http, _message);
  }


  /* public  listarCustos(idLoja: number) {
    let self = this;
  
    return this.get(`${this.url}/listarCustos/${idLoja}`)
            .toPromise()
            .then(res =><CustoOperacional>res);
          
    }*/

  public getCustos(idLoja: number) {
    let teste;

    return this.get(`${this.url}/listarCustos/${idLoja}`)
      .toPromise()
      .then(res => <CustoOperacional>res);
  }


}