import {GenericModel} from '../../shared/model/generic.model';
import {ItensNFEntrada} from './itens-nf-entrada.model';
import {DuplicataPagar} from '../../financeiro/duplicata-pagar/duplicata-pagar.model';
import { Prazo } from '../../financeiro/prazo/prazo.model';
import { Transportadora } from '../../administrativo/transportadora/transportadora.model';
import { NFEntradaLogAtualiza } from './nf-entrada-log-atualiza.model';

export class NFEntrada extends GenericModel {

	public lojaId:number;

	public lojaPessoaNomeFantasia:string;

	public numero:number;

	public numeroVenda:number;

	public idPrazo:number;

	public prazoDescricao:string; //new
	
	public itensNFEntrada:  Array<ItensNFEntrada>;
	
	public nfEntradaLogAtualiza: NFEntradaLogAtualiza;
	
	public operacaoId:number;
	
	public operacaoDescricao:string;

	public chaveAcesso: string;

	public compradorId:number;
	
	public compradorPessoaNomeFantasia:string;	
	
	public idTransportadora:number;

	public transportadoraPessoaNomeFantasia: string; //new

	public fornecedorId:number;//New
	
	public nomeFornecedor:string;//New

	public formaPagamentoDescricao:string;

	public formaPagamentoId:number;

	public dataNota :Date; 

	public dataEntrada :Date; 

	public dataCadastro: Date;
	
	public dataCancelamento:Date;

	public modelo:string;

	public serie:string;

	public outrasDespesasNota:number; 

	public total:number; 

	public valorTotalProdutos:number;

	public valorTotalServicos:number;

	public modalidadeFrete:number; //New

	public baseIcms:number; //new

	public valorIcms: number; //new

	public baseIcmsSubstituicao: number; //new

	public valorIcmsSubstituicao: number; //new

	public valorIPI:number;

	public valorCofins: number;

	public valorPis:number;

	public valorFrete:number;
	
	public valorSeguro:number;
	
	public valorOutrasDespesas:number;

	private aliquotaPis:number;

	private aliquotaCofins:number;

	private vicmsdeson:number;
	
	private vfcpufdest:number;
	
	private vicmsufdest:number;
	
	private vicmsufremet:number;

	public totalBrutoProduto:number//New;

	public totalLiquidoProduto:number//New;
	
	public valorDescontoProdutoItens:number;

	public valorDescontoServicoItens:number //New
	
	public percentualDescontoProdutoItens:number;

	public percentualDescontoServicoItens:number;//New
			
	public valorDescontoProdutoFechamento:number;
	
	public percentualDescontoProdutoFechamento:number;
	
	public subTotal:number;
	
	public observacao:string;

	public observacaoNotaFiscal: string; //New
	
	public faturado:string;
	
	public tipoFrete:string;
	
	public cancelado:string;

	//public especie:string;
    
	//public volume:string;
	
	public estado: string;
	
    public pesoLiquido:number;
	
	public pesoBruto:number;

	public duplicata:DuplicataPagar;


}
